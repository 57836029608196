<template>
  <div class="main">
    <router-view />
    <!-- :style="[route.path == '/ShadowActivity' ? { margin: 40px 0; paddingBottom: 200px; } : {}]" -->
  </div>
</template>

<script>
// import ShadowIndex from '../ShadowIndex/ShadowIndex'
// import ShadowComposer from '../ShadowComposer/ShadowComposer'
import { defineComponent, ref, watch, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
  name: "TgLayoutMain",
  // components: {
  // 	ShadowIndex,
  // 	ShadowComposer
  // },
  setup() {
    const router = useRouter();
    const route = useRoute()
    const nav_url = ref("/");
    const getUrl = function (value) {
      nav_url.value = value;
      router.push({
        path: nav_url.value,
      });
    };
    onMounted(() => {
      let a = document.querySelector(".main");
      (route.path == "/ShowActivity" || route.path == "/drawList") ? a.classList.add("main_") : a.classList.remove("main_");
    });
    let NavType = ref(2);
    watch(
      () => router.currentRoute.value.path,
      (newData, oldData) => {
        if (
          newData == "/" ||
          newData == "/ShadowComposer" ||
          newData == "/ShadowMaterial" ||
          newData == "/ShadowBusiness" ||
          newData == "/ShadowEquipment" ||
          newData == "/ShadowTrain" ||
          newData == "/ShadowIndexAllWorks"

        ) {
          NavType.value = 2;
        } else {
          NavType.value = 1;
        }
        if (newData != oldData) {
          window.scrollTo(0, 0);
        }
        let a = document.querySelector(".main");
        (newData == "/ShowActivity" || newData == "/drawList") ? a.classList.add("main_") : a.classList.remove("main_");
      },
      {
        immediate: false,
        deep: true,
      }
    )
    return {
      nav_url,
      getUrl,
      router,
      NavType,
      // getNavType,
      //   watch
    };
  },
});
</script>

<style scoped>
.main {
  flex-grow: 1;
  width: 100%;
  background: rgb(245, 245, 245);
  /* min-width:12.8rem; */
  /* margin:0 auto; */
}

.main_ {
  margin: 40px 0 !important;
  padding-bottom: 200px !important;
  background: rgb(245, 245, 245) !important;
}
</style>
