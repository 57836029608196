import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, isRef as _isRef, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/module_share_open.png'
import _imports_1 from '../../assets/bvlv_blue.png'
import _imports_2 from '../../assets/module_share_open.png'
import _imports_3 from '../../assets/lxhz_icon.png'


const _hoisted_1 = {
  key: 0,
  class: "accessListPopup"
}
const _hoisted_2 = { class: "aLP_center" }
const _hoisted_3 = { class: "wVC_list_l" }
const _hoisted_4 = ["onClick", "src"]
const _hoisted_5 = { class: "wVC_list_l_Title" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "wVC_list_l_T_T_name" }
const _hoisted_8 = { class: "wVC_list_l_T_T_time" }
const _hoisted_9 = { class: "wVC_list_l_T_B" }
const _hoisted_10 = { class: "wVC_list_l_T_B_location" }
const _hoisted_11 = { class: "wVC_list_r" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { style: {"margin-top":"0.26rem","background":"#fff","border-radius":"0.12rem"} }
const _hoisted_14 = { style: {"text-align":"center","padding-top":"5px","font-size":"20px"} }
const _hoisted_15 = { style: {"display":"flex","justify-content":"center"} }
const _hoisted_16 = { class: "zf_body" }
const _hoisted_17 = { class: "zf_body_top" }
const _hoisted_18 = { class: "zf_b_M" }
const _hoisted_19 = { class: "zf_b_M_number" }
const _hoisted_20 = { class: "zf_fs_body" }
const _hoisted_21 = { class: "zf_fs_list" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = ["src"]
const _hoisted_24 = {
  key: 0,
  class: "zf_fs_icon",
  src: _imports_3,
  alt: ""
}
const _hoisted_25 = { class: "zf_fs_b" }

import { ref } from "vue";
import { useRouter } from "vue-router";
import { Axios } from "@/assets/js/ls_com_axios.js";
import { ElMessage } from "element-plus";
import TwoDimensionalCode from "@/views/ShadowComposer/components/TwoDimensionalCode.vue";
const totalNumber = 10;
// 打开

export default /*@__PURE__*/_defineComponent({
  __name: 'Follow',
  setup(__props, { expose: __expose }) {

const TwoDimensionalCodeRef = ref<any>();
const router = useRouter();
const windowVisitChildren = ref(false);
const wVC_list = ref([]);
const windowVisit = () => {};
let page = ref({
  pageNum: 1,
  pageSize: 5,
  total: 0,
});
const sizeChange = (val) => {
  getVisit(memberId.value);
};
const currentChange = (val) => {
  getVisit(memberId.value);
};
const composer = function (index) {
  handleClose();
  router.push({
    path: "/ShadowComposerHomepage",
    query: { id: index.followId },
  });
};
// 获取最近访问
const memberId = ref([]);
const getVisit = (id) => {
  let obj = { ...page.value };
  obj.memberId = id;
  Axios("GET", "/member/member-follow/list", obj, false, function (res) {
    wVC_list.value = res.data.rows;
    page.value.total = res.data.total;
  });
};
const init = (id) => {
  windowVisitChildren.value = true;
  memberId.value = id;
  getVisit(memberId.value);
};
//关闭
const handleClose = () => {
  windowVisitChildren.value = false;
};
// 联系
const PartnersMobileNumber = ref(false);
const PartnersMobileNumber1 = ref();
let Contact = function (index) {
  Axios(
    "get",
    "/member/contacts/phone/" + index,
    {},
    false,
    function (res) {
      PartnersMobileNumber.value = true;
      PartnersMobileNumber1.value = res.data.msg;
    },
    function (res) {
      ElMessage({
        showClose: true,
        message: res.data.msg,
        type: "warning",
      });
      console.log(res.data.data);

      getMoney(index);
    }
  );
};
let moenyNumber = ref(0);
let payment = ref(false);
let paymentTL_click = function (index) {
  for (let i = 0; i < paymentTypeList.value.length; i++) {
    if (index == paymentTypeList.value[i]) {
      paymentTypeList.value[i].checked = true;
    } else {
      paymentTypeList.value[i].checked = false;
    }
  }
};
// 支付类型列表
let paymentTypeList = ref([
  // {
  //   title: "我的账户",
  //   img: require("../../assets/lxhz_pb.png"),
  //   checked: true,
  // },
  {
    title: "微信",
    img: require("@/assets/lxhz_wx.png"),
    checked: true,
  },
  {
    title: "支付宝",
    img: require("@/assets/lxhz_zfb.png"),
    checked: false,
  },
]);
let obj = ref({});
let getMoney = function (index) {
  Axios("get", "/member/contacts/pay-amount", {}, false, function (res) {
    moenyNumber.value = res.data.msg;
    obj.value = {};
    obj.value = index;
    payment.value = true;
  });
};
// 支付获取联系方式
let get_sjh = function (obj) {
  let index = obj;
  Axios(
    "POST",
    "/member/contacts?memberId=" + index,
    {},
    false,
    function (res) {
      console.log(res.data);
    },
    function (res) {
      Axios(
        "POST",
        `/member/contacts/create-order/${index}`,
        {},
        false,
        function (res) {
          console.log();

          // console.log(res.data.data);
          TwoDimensionalCodeRef.value.init(
            res.data.data.qrcode,
            res.data.data.sceneId,
            index
          );
        },
        function (res) {
          console.log(res.data);
        }
      );
    }
  );
};
const CloseQrCode = (e) => {
  payment.value = false;
  Axios(
    "get",
    "/member/contacts/phone/" + e,
    {},
    false,
    function (res) {
      // res;
      PartnersMobileNumber.value = true;
      PartnersMobileNumber1.value = res.data.msg;
      // PartnersMobileNumber1.value = res.data.data.info.partyBContactInfo;
    },
    function (res) {
      console.log("没购买");
    }
  );
};
__expose({ init, handleClose });

return (_ctx: any,_cache: any) => {
  const _component_el_pagination = _resolveComponent("el-pagination")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (windowVisitChildren.value)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", { class: "aLP_center_top" }, [
            _cache[9] || (_cache[9] = _createElementVNode("div", null, "关注", -1)),
            _createElementVNode("img", {
              onClick: handleClose,
              src: _imports_0
            })
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(wVC_list.value, (index) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "wVC_list",
              key: index
            }, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("img", {
                  class: "wVC_list_l_userImg",
                  onClick: ($event: any) => (composer(index)),
                  src: index.avatar ? index.avatar : require('@/assets/logo.png')
                }, null, 8, _hoisted_4),
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", {
                    class: "wVC_list_l_T_T",
                    onClick: ($event: any) => (composer(index))
                  }, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(index.memberName), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(index.timeTitle), 1)
                  ], 8, _hoisted_6),
                  _createElementVNode("div", _hoisted_9, [
                    _cache[10] || (_cache[10] = _createElementVNode("div", { class: "wVC_list_l_T_B_rz" }, [
                      _createElementVNode("img", { src: _imports_1 }),
                      _createTextVNode(" 标识山西认证导演 ")
                    ], -1)),
                    _createElementVNode("span", _hoisted_10, _toDisplayString(index.address || "暂无具体地址"), 1)
                  ])
                ])
              ]),
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  class: "wVC_list_r_gz",
                  onClick: ($event: any) => (composer(index))
                }, " 进入主页 ", 8, _hoisted_12)
              ])
            ]))
          }), 128)),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_el_pagination, {
              "current-page": _unref(page).pageNum,
              "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_unref(page).pageNum) = $event)),
              "page-size": _unref(page).pageSize,
              "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_unref(page).pageSize) = $event)),
              onSizeChange: sizeChange,
              onCurrentChange: currentChange,
              style: {"display":"flex","justify-content":"center"},
              background: "",
              layout: "prev, pager, next",
              total: _unref(page).total
            }, null, 8, ["current-page", "page-size", "total"])
          ])
        ]),
        _createVNode(_component_el_dialog, {
          modelValue: PartnersMobileNumber.value,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((PartnersMobileNumber).value = $event)),
          "align-center": "",
          width: "480px",
          "close-on-click-modal": false
        }, {
          default: _withCtx(() => [
            _cache[11] || (_cache[11] = _createElementVNode("div", { style: {"text-align":"center"} }, "联系方式", -1)),
            _createElementVNode("div", _hoisted_14, _toDisplayString(PartnersMobileNumber1.value), 1),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("div", {
                class: "dataBtnClose",
                onClick: _cache[2] || (_cache[2] = ($event: any) => (PartnersMobileNumber.value = false))
              }, " 取消 "),
              _createElementVNode("div", {
                class: "dataBtnSubmit",
                onClick: _cache[3] || (_cache[3] = ($event: any) => (PartnersMobileNumber.value = false))
              }, " 确认 ")
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_el_dialog, {
          title: "",
          "show-close": false,
          modelValue: _unref(payment),
          "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (_isRef(payment) ? (payment).value = $event : payment = $event)),
          "align-center": "",
          width: "4.8rem",
          "close-on-click-modal": false
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("div", _hoisted_17, [
                _cache[12] || (_cache[12] = _createElementVNode("span", null, "联系合作", -1)),
                _createElementVNode("img", {
                  onClick: _cache[5] || (_cache[5] = ($event: any) => (_isRef(payment) //@ts-ignore
 ? payment.value = false : payment = false)),
                  class: "cur",
                  src: _imports_2
                })
              ]),
              _cache[16] || (_cache[16] = _createElementVNode("div", { class: "zf_body_title" }, "支付相应费用，获得联系方式", -1)),
              _createElementVNode("div", _hoisted_18, [
                _cache[14] || (_cache[14] = _createElementVNode("span", { CLASS: "zf_b_M_title" }, "应付金额：", -1)),
                _createElementVNode("span", _hoisted_19, [
                  _cache[13] || (_cache[13] = _createElementVNode("text", { class: "zf_b_M_n_icon" }, "￥", -1)),
                  _createTextVNode(_toDisplayString((_unref(moenyNumber) / 1).toFixed(2)), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_20, [
                _cache[15] || (_cache[15] = _createElementVNode("div", { class: "zf_fs_title" }, "选择付款方式", -1)),
                _createElementVNode("div", _hoisted_21, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(paymentTypeList), (index) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: index,
                      onClick: ($event: any) => (_unref(paymentTL_click)(index))
                    }, [
                      _createElementVNode("img", {
                        class: "zf_fs_logo cur",
                        src: index.img
                      }, null, 8, _hoisted_23),
                      _createElementVNode("span", null, _toDisplayString(index.title), 1),
                      (index.checked)
                        ? (_openBlock(), _createElementBlock("img", _hoisted_24))
                        : _createCommentVNode("", true)
                    ], 8, _hoisted_22))
                  }), 128))
                ])
              ]),
              _createElementVNode("div", _hoisted_25, [
                _createElementVNode("div", {
                  class: "zf_fs_b_qx cur",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_isRef(payment) //@ts-ignore
 ? payment.value = false : payment = false))
                }, "取消"),
                _createElementVNode("div", {
                  class: "zf_fs_b_qd cur",
                  onClick: _cache[7] || (_cache[7] = ($event: any) => (_unref(get_sjh)(_unref(obj))))
                }, "确定")
              ])
            ])
          ]),
          _: 1
        }, 8, ["modelValue"]),
        _createVNode(TwoDimensionalCode, {
          ref_key: "TwoDimensionalCodeRef",
          ref: TwoDimensionalCodeRef,
          CloseQrCode: CloseQrCode
        }, null, 512)
      ]))
    : _createCommentVNode("", true)
}
}

})