<template>
  <!-- 关注 -->
  <div v-if="windowVisitChildren" class="accessListPopup">
    <div class="aLP_center">
      <div class="aLP_center_top">
        <div>关注</div>
        <img @click="handleClose" src="@/assets/module_share_open.png" />
      </div>
      <!-- 数据 -->
      <div class="wVC_list" v-for="index in wVC_list" :key="index">
        <div class="wVC_list_l">
          <img
            class="wVC_list_l_userImg"
            @click="composer(index)"
            :src="index.avatar ? index.avatar : require('@/assets/logo.png')"
          />
          <div class="wVC_list_l_Title">
            <div class="wVC_list_l_T_T" @click="composer(index)">
              <div class="wVC_list_l_T_T_name">{{ index.memberName }}</div>
              <div class="wVC_list_l_T_T_time">{{ index.timeTitle }}</div>
            </div>
            <div class="wVC_list_l_T_B">
              <div class="wVC_list_l_T_B_rz">
                <img src="../../assets/bvlv_blue.png" />
                标识山西认证导演
              </div>
              <span class="wVC_list_l_T_B_location">{{
                index.address || "暂无具体地址"
              }}</span>
            </div>
          </div>
        </div>
        <div class="wVC_list_r">
          <!-- <button class="wVC_list_r_lx" @click="Contact(index.memberId)">
            联系
          </button> -->
          <button class="wVC_list_r_gz" @click="composer(index)">
            进入主页
          </button>
        </div>
      </div>
      <!--      dispaly: block;
          position: absolute;
          bottom: 0;
          left: calc(50% - 50px); -->
      <div
        style="margin-top: 0.26rem; background: #fff; border-radius: 0.12rem"
      >
        <el-pagination
          v-model:current-page="page.pageNum"
          v-model:page-size="page.pageSize"
          @size-change="sizeChange"
          @current-change="currentChange"
          style="display: flex; justify-content: center"
          background
          layout="prev, pager, next"
          :total="page.total"
        />
      </div>
    </div>
    <!-- 手机号 -->
    <el-dialog
      v-model="PartnersMobileNumber"
      align-center
      width="480px"
      :close-on-click-modal="false"
    >
      <div style="text-align: center">联系方式</div>
      <div style="text-align: center; padding-top: 5px; font-size: 20px">
        {{ PartnersMobileNumber1 }}
      </div>
      <div style="display: flex; justify-content: center">
        <div class="dataBtnClose" @click="PartnersMobileNumber = false">
          取消
        </div>
        <div class="dataBtnSubmit" @click="PartnersMobileNumber = false">
          确认
        </div>
      </div>
    </el-dialog>
    <!-- 支付组件 -->
    <el-dialog
      title=""
      :show-close="false"
      v-model="payment"
      align-center
      width="4.8rem"
      :close-on-click-modal="false"
    >
      <div class="zf_body">
        <div class="zf_body_top">
          <span>联系合作</span>
          <img
            @click="payment = false"
            class="cur"
            src="../../assets/module_share_open.png"
          />
        </div>
        <div class="zf_body_title">支付相应费用，获得联系方式</div>
        <div class="zf_b_M">
          <span CLASS="zf_b_M_title">应付金额：</span>
          <span class="zf_b_M_number"
            ><text class="zf_b_M_n_icon">￥</text
            >{{ (moenyNumber / 1).toFixed(2) }}</span
          >
        </div>
        <div class="zf_fs_body">
          <div class="zf_fs_title">选择付款方式</div>
          <div class="zf_fs_list">
            <div
              v-for="index in paymentTypeList"
              :key="index"
              @click="paymentTL_click(index)"
            >
              <img class="zf_fs_logo cur" :src="index.img" />
              <span>{{ index.title }}</span>
              <img
                v-if="index.checked"
                class="zf_fs_icon"
                src="../../assets/lxhz_icon.png"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="zf_fs_b">
          <div class="zf_fs_b_qx cur" @click="payment = false">取消</div>
          <div class="zf_fs_b_qd cur" @click="get_sjh(obj)">确定</div>
        </div>
      </div>
    </el-dialog>
    <two-dimensional-code
      ref="TwoDimensionalCodeRef"
      :CloseQrCode="CloseQrCode"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { Axios } from "@/assets/js/ls_com_axios.js";
import { ElMessage } from "element-plus";
import TwoDimensionalCode from "@/views/ShadowComposer/components/TwoDimensionalCode.vue";
const TwoDimensionalCodeRef = ref<any>();
const router = useRouter();
const windowVisitChildren = ref(false);
const wVC_list = ref([]);
const windowVisit = () => {};
let page = ref({
  pageNum: 1,
  pageSize: 5,
  total: 0,
});
const sizeChange = (val) => {
  getVisit(memberId.value);
};
const currentChange = (val) => {
  getVisit(memberId.value);
};
const composer = function (index) {
  handleClose();
  router.push({
    path: "/ShadowComposerHomepage",
    query: { id: index.followId },
  });
};
// 获取最近访问
const memberId = ref([]);
const getVisit = (id) => {
  let obj = { ...page.value };
  obj.memberId = id;
  Axios("GET", "/member/member-follow/list", obj, false, function (res) {
    wVC_list.value = res.data.rows;
    page.value.total = res.data.total;
  });
};
const totalNumber = 10;
// 打开
const init = (id) => {
  windowVisitChildren.value = true;
  memberId.value = id;
  getVisit(memberId.value);
};
//关闭
const handleClose = () => {
  windowVisitChildren.value = false;
};
// 联系
const PartnersMobileNumber = ref(false);
const PartnersMobileNumber1 = ref();
let Contact = function (index) {
  Axios(
    "get",
    "/member/contacts/phone/" + index,
    {},
    false,
    function (res) {
      PartnersMobileNumber.value = true;
      PartnersMobileNumber1.value = res.data.msg;
    },
    function (res) {
      ElMessage({
        showClose: true,
        message: res.data.msg,
        type: "warning",
      });
      console.log(res.data.data);

      getMoney(index);
    }
  );
};
let moenyNumber = ref(0);
let payment = ref(false);
let paymentTL_click = function (index) {
  for (let i = 0; i < paymentTypeList.value.length; i++) {
    if (index == paymentTypeList.value[i]) {
      paymentTypeList.value[i].checked = true;
    } else {
      paymentTypeList.value[i].checked = false;
    }
  }
};
// 支付类型列表
let paymentTypeList = ref([
  // {
  //   title: "我的账户",
  //   img: require("../../assets/lxhz_pb.png"),
  //   checked: true,
  // },
  {
    title: "微信",
    img: require("@/assets/lxhz_wx.png"),
    checked: true,
  },
  {
    title: "支付宝",
    img: require("@/assets/lxhz_zfb.png"),
    checked: false,
  },
]);
let obj = ref({});
let getMoney = function (index) {
  Axios("get", "/member/contacts/pay-amount", {}, false, function (res) {
    moenyNumber.value = res.data.msg;
    obj.value = {};
    obj.value = index;
    payment.value = true;
  });
};
// 支付获取联系方式
let get_sjh = function (obj) {
  let index = obj;
  Axios(
    "POST",
    "/member/contacts?memberId=" + index,
    {},
    false,
    function (res) {
      console.log(res.data);
    },
    function (res) {
      Axios(
        "POST",
        `/member/contacts/create-order/${index}`,
        {},
        false,
        function (res) {
          console.log();

          // console.log(res.data.data);
          TwoDimensionalCodeRef.value.init(
            res.data.data.qrcode,
            res.data.data.sceneId,
            index
          );
        },
        function (res) {
          console.log(res.data);
        }
      );
    }
  );
};
const CloseQrCode = (e) => {
  payment.value = false;
  Axios(
    "get",
    "/member/contacts/phone/" + e,
    {},
    false,
    function (res) {
      // res;
      PartnersMobileNumber.value = true;
      PartnersMobileNumber1.value = res.data.msg;
      // PartnersMobileNumber1.value = res.data.data.info.partyBContactInfo;
    },
    function (res) {
      console.log("没购买");
    }
  );
};
defineExpose({ init, handleClose });
</script>
<style scoped>
.accessListPopup {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(8px);
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.2);
  z-index: 9999;
}
.aLP_center {
  display: block;
  margin: 0 auto;
  margin-top: 1.14rem;
  border-radius: 0.12rem;
  background: #fff;
  width: 6rem;
  height: calc(9rem - 3.2rem);
  /* max-height: calc(9rem - 3.2rem);
  min-height: calc(9rem - 3.2rem); */
  min-height: 2.4rem;
  padding: 0.17rem 0.24rem 0.8rem;
  position: relative;
  overflow: auto;
}

.aLP_center_top {
  display: flex;
  justify-content: space-between;
}

.aLP_center_top > div {
  font-size: 0.16rem;
  color: #333333;
}

.aLP_center_top > img {
  width: 0.2rem;
}
.wVC_list {
  display: flex;
  justify-content: space-between;
  margin-top: 0.2rem;
}

.wVC_list_l_userImg {
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  margin-right: 0.12rem;
}

.wVC_list_l {
  display: flex;
  justify-content: flex-start;
}

.wVC_list_l_Title {
  display: flex;
  flex-direction: column;
}

.wVC_list_l_Title > div {
  display: flex;
  /* flex-direction: row-reverse; */
  align-items: center;
}

.wVC_list_l_T_T_name {
  font-size: 0.16rem;
  color: #333333;
  margin-right: 0.08rem;
}

.wVC_list_l_T_T_time {
  color: #86909c;
  font-size: 0.12rem;
}

.wVC_list_l_T_B {
  margin-top: 0.06rem;
}

.wVC_list_l_T_B_rz {
  color: #015243;
  background: #e1fefc;
  border-radius: 0.1rem;
  font-size: 0.12rem;
  padding: 0.02rem 0.16rem 0.02rem 0.04rem;
  display: flex;
  align-items: center;
}

.wVC_list_l_T_B_rz > img {
  width: 0.13rem;
  margin-right: 0.09rem;
}

.wVC_list_r {
  display: flex;
  align-items: center;
}

.wVC_list_r > button {
  padding: 0.06rem 0.16rem;
  font-size: 0.12rem;
  border-radius: 0.04rem;
}

.wVC_list_r_lx {
  border: 0.01rem solid #333333;
  color: #333333;
  margin-right: 0.16rem;
}

.wVC_list_r_gz {
  background: #c01a26;
  color: #ffffff;
  width: calc();
  border: 0rem solid #333333;
}

/* 弹出框 */
.zf_body {
  border-radius: 0.12rem;
}

.zf_body_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;
}

.zf_body_top > span {
  color: #333333;
  font-size: 0.16rem;
}

.zf_body_top > img {
  width: 0.2rem;
}

.zf_body_title {
  color: #86909c;
  font-size: 0.14rem;
  text-align: center;
  margin-bottom: 0.12rem;
}

.zf_b_M {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.zf_b_M_title {
  font-size: 0.14rem;
  color: #000000;
}

.zf_b_M_number {
  font-size: 0.24rem;
  color: #c01a26;
}

.zf_b_M_n_icon {
  font-size: 0.14rem;
}

.zf_fs_body {
  margin-top: 0.2rem;
}

.zf_fs_title {
  color: #000000;
  font-size: 0.14rem;
  margin-bottom: 0.12rem;
}

.zf_fs_list {
  display: flex;
}

.zf_fs_list > div {
  position: relative;
  border-radius: 0.04rem;
  border: 1px solid #e5e6eb;
  padding: 0.08rem 0.24rem;
  margin-right: 0.12rem;
  display: flex;
  align-items: center;
}

.zf_fs_logo {
  width: 0.24rem;
  margin-right: 0.04rem;
}

.zf_fs_icon {
  width: 0.25rem;
  position: absolute;
  right: 0;
  bottom: 0;
}

.zf_fs_b {
  display: flex;
  justify-content: center;
}

.zf_fs_b > div {
  padding: 0.08rem 0.3rem;
  font-size: 0.14rem;
  border-radius: 0.08rem;
  border: 0.01rem solid #333333;
}

.zf_fs_b_qx {
  color: #333333;
}

.zf_fs_b > .zf_fs_b_qd {
  color: #ffffff;
  border: 0.01rem solid #c01a26;
  background: #c01a26;
  margin-left: 0.2rem;
}

.zf_fs_body {
  margin-bottom: 0.2rem;
}
</style>
