import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, createTextVNode as _createTextVNode, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"
import _imports_0 from '@/assets/moduleSIWP_bf.png'
import _imports_1 from '@/assets/moduleSIWP_jb.png'
import _imports_2 from '@/assets/zt_404.png'
import _imports_3 from '@/assets/publick_open.png'
import _imports_4 from '@/assets/module_share_open.png'


const _hoisted_1 = { style: {"width":"100%","height":"100%"} }
const _hoisted_2 = { class: "body" }
const _hoisted_3 = { style: {"margin-bottom":"0.2rem"} }
const _hoisted_4 = { class: "bodyUser" }
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "bodyUsertitle" }
const _hoisted_7 = { class: "BUT_name" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { style: {"margin-bottom":"0.2rem"} }
const _hoisted_10 = { class: "body_video" }
const _hoisted_11 = { class: "body_video_left" }
const _hoisted_12 = { class: "body_VL_name" }
const _hoisted_13 = { class: "body_VL_xx" }
const _hoisted_14 = { key: 0 }
const _hoisted_15 = { key: 1 }
const _hoisted_16 = {
  key: 2,
  style: {"margin-left":"5px"}
}
const _hoisted_17 = { key: 0 }
const _hoisted_18 = ["src"]
const _hoisted_19 = {
  key: 3,
  style: {"margin-left":"5px"},
  class: "circle-span"
}
const _hoisted_20 = { class: "body_VL_message" }
const _hoisted_21 = { class: "body_VL_messageList" }
const _hoisted_22 = ["src"]
const _hoisted_23 = ["src"]
const _hoisted_24 = { class: "body_VL_introduction" }
const _hoisted_25 = { class: "body_VL_introduction_nr" }
const _hoisted_26 = { class: "body_VL_introduction_label" }
const _hoisted_27 = { class: "body_video_right" }
const _hoisted_28 = ["onClick"]
const _hoisted_29 = { class: "body_video_list_left" }
const _hoisted_30 = ["src"]
const _hoisted_31 = { class: "body_VLL_time" }
const _hoisted_32 = { class: "body_VLL_title" }
const _hoisted_33 = { class: "body_VLL_t_title" }
const _hoisted_34 = { class: "body_VLL_t_gg" }
const _hoisted_35 = {
  key: 1,
  class: "body_video_list"
}
const _hoisted_36 = {
  key: 2,
  class: "body_video_right_t"
}
const _hoisted_37 = { class: "open_list" }
const _hoisted_38 = { class: "open_list_img" }
const _hoisted_39 = ["src"]
const _hoisted_40 = { class: "open_list_xx" }
const _hoisted_41 = ["src"]
const _hoisted_42 = ["src"]
const _hoisted_43 = {
  key: 0,
  class: "module_share"
}
const _hoisted_44 = {
  key: 1,
  class: "module_inform"
}
const _hoisted_45 = { class: "module_inform_body" }

import com_date from "@/assets/js/common.js";
import { useRouter } from "vue-router";
import moduleShare from "../../../components/moduleShare.vue";
import { ref } from "vue";
import { Axios } from "@/assets/js/ls_com_axios.js";
import { ElMessage } from "element-plus";

export default /*@__PURE__*/_defineComponent({
  __name: 'ShadowIndexWParticulars',
  props: {
  workId: String,
},
  emits: [
  "openClickA",
  "openClickOverClose",
  "openClickOverOpen",
  "openShowInfo",
],
  setup(__props, { expose: __expose, emit: __emit }) {

const emit = __emit;
const router = useRouter();
const type = ref();
// const bodyStyle = document.body.style;
const props = __props;
const jobs = ref([]);
const tags = ref([]);
const item = ref({});
const reason = ref("");
const list = ref([]);
const otherlist = ref([]);
const ids = ref([]);
let id = ref("");
const memberId = ref(JSON.parse(localStorage.getItem("userList")).memberId);
const AuthorizationModeList = ref({
  1: {},
  2: {
    img: [
      "AuthorizationMode3.png",
      "AuthorizationMode4.png",
      "AuthorizationMode1.png",
    ],
  },
  3: {
    img: [
      "AuthorizationMode3.png",
      "AuthorizationMode4.png",
      "AuthorizationMode2.png",
    ],
  },
  4: {
    img: ["AuthorizationMode3.png", "AuthorizationMode4.png"],
  },
  5: {
    img: ["AuthorizationMode3.png", "AuthorizationMode1.png"],
  },
  6: {
    img: ["AuthorizationMode3.png", "AuthorizationMode2.png"],
  },
  7: {
    img: ["AuthorizationMode3.png"],
  },
});
const getInfo = () => {
  Axios(
    "GET",
    "/material/material/work/" + id.value,
    null,
    false,
    function (res) {
      item.value = res.data.data;
      console.log(res.data.data);
      console.log(res.data.data);

      playerOptions.value.sources[0].src = item.value.video;
      if (item.value.tags) {
        tags.value = item.value.tags.split(",");
      }
      if (item.value.jobs) {
        jobs.value = item.value.jobs.split(",");
      }
      handleCollectType.value = item.value.isFavorite == 1 ? true : false;
      handleLikeType.value = item.value.isLike == 1 ? true : false;
      getList();
      getOtherList();
      getuserMassage();
      Axios(
        "POST",
        "/material/material/work/play?id=" + id.value,
        null,
        false,
        function (res) {
          item.value.plays++;
        }
      );
    }
  );
};
const idsfollow = () => {
  // member / member - follow / follow - ids
  Axios("GET", "/member/member-follow/follow-ids", null, false, function (res) {
    console.log("res", res);
    ids.value = res.data.data;
  });
};

const getInfo1 = () => {
  Axios(
    "GET",
    "/material/material/work/" + id.value,
    null,
    false,
    function (res) {
      item.value = res.data.data;
      handleCollectType.value = item.value.isFavorite == 1 ? true : false;
      handleLikeType.value = item.value.isLike == 1 ? true : false;
    }
  );
};
const rwm = ref("");
const share = () => {
  Axios(
    "POST",
    "/material/material/work/share?id=" + id.value,
    null,
    false,
    function (res) {
      console.info(res);
      rwm.value = res.data.data.qrcodeUrl;
    }
  );
};
const isFollow = ref(true);
let getuserMassage = function () {
  Axios(
    "GET",
    "/member/info/" + item.value.createUserId,
    false,
    false,
    function (res) {
      console.log(res);
      isFollow.value == res.data.data.isFollow;
    }
  );
};
const videoPlayerRef = ref();
const openClick = function () {
  videoPlayerRef.value.player.pause(); // 关闭时暂停视频
  emit("openClickA"); // bodyStyle.overflowY = "auto";
};
const ParticularsChecked = ref(false);
const ParticularsOver = function () {
  var cInput = document.createElement("input");
  cInput.value = window.location.href;
  document.body.appendChild(cInput);
  cInput.select(); // 选取文本框内容

  // 执行浏览器复制命令
  // 复制命令会将当前选中的内容复制到剪切板中（这里就是创建的input标签）
  // Input要在正常的编辑状态下原生复制方法才会生效

  document.execCommand("copy");
  // ParticularsChecked.value = true;
  // emit("openClickOverClose");
};
const ParticularsOverNo = function () {
  ParticularsChecked.value = false;
  emit("openClickOverOpen");
};
const module_informChecked = ref(false);
const module_informOver = function () {
  module_informChecked.value = true;
  emit("openClickOverClose");
};
const module_informOverNo = function () {
  module_informChecked.value = false;
  emit("openClickOverOpen");
};
const handleReport = function () {
  if (type.value == "") {
    // 添加提示必填项
  } else {
    Axios(
      "POST",
      "/material/material/workReport/sou",
      { reason: reason.value, type: type.value, workId: id.value },
      null,
      false,
      function (res) {
        ElMessage.success("举报成功");
        module_informChecked.value = false;
        emit("openClickOverOpen");
      }
    );
  }
};
const getList = function () {
  Axios(
    "POST",
    "/material/material/work/otherWorks",
    {
      condition: {
        publish_mode: 2,
        userId: item.value.createUserId,
        workId: id.value,
      },
      pageIndex: 1,
      pageSize: 3,
      sort: "likes",
    },
    null,
    false,
    function (res) {
      list.value = res.data.data.records;
    }
  );
};
const getOtherList = function () {
  Axios(
    "POST",
    "/material/material/work/list",
    {
      condition: {
        publish_mode: 2,
        category1_id: item.value.category1Id,
      },
      pageIndex: 1,
      pageSize: 4,
      sort: "likes",
    },
    null,
    false,
    function (res) {
      otherlist.value = res.data.data.records;
    }
  );
};
const playerOptions = ref({
  autoplay: false,
  controls: true,
  sources: [
    {
      src: "",
      type: "video/mp4",
    },
  ],
});
const goPage = function () {
  router.push({
    path: "ShadowComposerHomepage",
    query: { id: item.value.createUserId },
  });
  openClick();
};
const handleLikeType = ref(false); // 点赞状态

const handleLike = function () {
  Axios(
    "POST",
    "/material/material/work/like?id=" + id.value,
    null,
    false,
    function (res) {
      // if (handleLikeType.value == true) {
      //   item.value.likes = item.value.likes + 1;
      // } else {
      //   item.value.likes = item.value.likes - 1;
      // }
      // handleLikeType.value = !handleLikeType.value;
      getInfo1();
      // console.log(item.value);
    }
  );
};
const handleCollectType = ref(false); // 收藏状态
const handleCollect = function () {
  Axios(
    "POST",
    "/material/material/work/favorite?id=" + id.value,
    null,
    false,
    function (res) {
      // if (handleCollectType.value == true) {
      //   item.value.favorites = item.value.favorites + 1;
      // } else {
      //   item.value.favorites = item.value.favorites - 1;
      // }
      // handleCollectType.value = !handleCollectType.value;
      getInfo1();
    }
  );
};
const showInfo = function (o) {
  // module_informChecked.value = false;
  id.value = o.id;
  console.log("propspropspropspropspropspropsprops.workId ", id.value);

  item.value = {};
  init(1);
  // emit("openShowInfo", o.id);
};
const handleAdd = function () {
  Axios(
    "POST",
    "/member/member-follow",
    { memberId: item.value.createUserId },
    false,
    function (res) {
      ElMessage({
        showClose: true,
        message: "关注成功",
        type: "success",
      });
      // isFollow.value = true;
      getInfo1();
      idsfollow();
    }
  );
};
const handleDelete = function () {
  Axios(
    "DELETE",
    "/member/member-follow",
    { memberId: item.value.createUserId },
    false,
    function (res) {
      // console.log("ressssssssssssssssssssssssssssssss", res);
      // isFollow.value = false;
      ElMessage({
        showClose: true,
        message: "已取消关注",
        type: "success",
      });
      getInfo1();
      idsfollow();

      // getuserMassage();
    }
  );
};

const init = function async(type = 0) {
  console.log("typetypetypetypetypetypetypetypetypetypetypetypetype", type);

  if (props.workId) {
    type == 0 ? (id.value = props.workId) : "";
    if (id.value) {
      getInfo();
      idsfollow();
      share();
    }
  }
};

__expose({ init, openClick });

return (_ctx: any,_cache: any) => {
  const _component_video_player = _resolveComponent("video-player")!
  const _component_el_radio = _resolveComponent("el-radio")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_radio_group = _resolveComponent("el-radio-group")!

  return (_openBlock(), _createElementBlock("view", _hoisted_1, [
    _createElementVNode("view", _hoisted_2, [
      _createElementVNode("view", _hoisted_3, [
        _createElementVNode("view", _hoisted_4, [
          _createElementVNode("img", {
            class: "bodyUserImg",
            src: item.value.avatar ? item.value.avatar : require('@/assets/logo.png')
          }, null, 8, _hoisted_5),
          _createElementVNode("view", _hoisted_6, [
            _createElementVNode("view", null, [
              _createElementVNode("span", _hoisted_7, _toDisplayString(item.value.nickName), 1)
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(jobs.value, (job, index) => {
              return (_openBlock(), _createElementBlock("view", {
                class: "BUT_sf",
                key: index
              }, _toDisplayString(job), 1))
            }), 128))
          ])
        ]),
        (item.value.createUserId != memberId.value)
          ? (_openBlock(), _createElementBlock("view", _hoisted_8, [
              (ids.value.includes(Number(item.value.createUserId)))
                ? (_openBlock(), _createElementBlock("view", {
                    key: 0,
                    class: "BU_gz",
                    style: {"color":"#fff","background":"#86909c","border":"0.01rem solid #86909c"},
                    onClick: handleDelete
                  }, "已关注"))
                : (_openBlock(), _createElementBlock("view", {
                    key: 1,
                    class: "BU_gz",
                    onClick: handleAdd
                  }, "关注"))
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("view", _hoisted_9, [
        _createVNode(_component_video_player, {
          class: "custom-video-player",
          ref_key: "videoPlayerRef",
          ref: videoPlayerRef,
          options: playerOptions.value
        }, null, 8, ["options"])
      ]),
      _createElementVNode("view", _hoisted_10, [
        _createElementVNode("view", _hoisted_11, [
          _createElementVNode("p", _hoisted_12, _toDisplayString(item.value.title), 1),
          _createElementVNode("p", _hoisted_13, [
            _createElementVNode("span", null, _toDisplayString(item.value.category1) + "-" + _toDisplayString(item.value.category2), 1),
            _cache[2] || (_cache[2] = _createTextVNode(" | ")),
            _createElementVNode("span", null, _toDisplayString(_unref(com_date).com_date.timestamp_string(
                item.value.createTime,
                true,
                "-",
                ":"
              )), 1),
            _cache[3] || (_cache[3] = _createTextVNode(" | ")),
            (item.value.publishAddress)
              ? (_openBlock(), _createElementBlock("span", _hoisted_14, _toDisplayString(item.value.publishAddress), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_15, "未知")),
            (item.value.authorizationMode != 1)
              ? (_openBlock(), _createElementBlock("span", _hoisted_16, [
                  (AuthorizationModeList.value[item.value.authorizationMode])
                    ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(AuthorizationModeList.value[
                    item.value.authorizationMode
                  ].img, (img, imgIndex) => {
                          return (_openBlock(), _createElementBlock("img", {
                            key: imgIndex,
                            src: require(`@/assets/${img}`),
                            style: {"width":"20px","height":"20px","border":"1px solid #fff","border-radius":"50%"}
                          }, null, 8, _hoisted_18))
                        }), 128))
                      ]))
                    : _createCommentVNode("", true)
                ]))
              : (_openBlock(), _createElementBlock("span", _hoisted_19))
          ]),
          _createElementVNode("view", _hoisted_20, [
            _createElementVNode("view", _hoisted_21, [
              _createElementVNode("view", null, [
                _cache[4] || (_cache[4] = _createElementVNode("img", { src: _imports_0 }, null, -1)),
                _createTextVNode(" " + _toDisplayString(item.value.plays), 1)
              ]),
              _createElementVNode("view", { onClick: handleLike }, [
                _createElementVNode("img", {
                  src: 
                    handleLikeType.value
                      ? require('@/assets/moduleSIWP_dz.png')
                      : require('@/assets/moduleSIWP_dzLanSe.png')
                  
                }, null, 8, _hoisted_22),
                _createTextVNode(" " + _toDisplayString(item.value.likes), 1)
              ]),
              _createElementVNode("view", { onClick: handleCollect }, [
                _createElementVNode("img", {
                  src: 
                    handleCollectType.value
                      ? require('@/assets/moduleSIWP_sc.png')
                      : require('@/assets/moduleSIWP_scLanSe.png')
                  
                }, null, 8, _hoisted_23),
                _createTextVNode(" " + _toDisplayString(item.value.favorites), 1)
              ])
            ]),
            _createElementVNode("view", {
              onClick: module_informOver,
              class: "body_VL_message_jb"
            }, _cache[5] || (_cache[5] = [
              _createElementVNode("img", { src: _imports_1 }, null, -1),
              _createTextVNode(" 举报 ")
            ]))
          ]),
          _createElementVNode("view", _hoisted_24, [
            _cache[6] || (_cache[6] = _createElementVNode("p", { class: "body_VL_introduction_title" }, "视频简介", -1)),
            _createElementVNode("p", _hoisted_25, _toDisplayString(item.value.introduction), 1),
            _createElementVNode("view", _hoisted_26, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags.value, (tag, index) => {
                return (_openBlock(), _createElementBlock("view", { key: index }, _toDisplayString(tag), 1))
              }), 128))
            ])
          ])
        ]),
        _createElementVNode("view", _hoisted_27, [
          _createElementVNode("view", { class: "body_video_right_t" }, [
            _cache[7] || (_cache[7] = _createElementVNode("span", { class: "body_video_right_t_title" }, " 创作人其他作品 ", -1)),
            _createElementVNode("view", {
              class: "body_video_right_t_nav",
              onClick: goPage
            }, " 查看全部 > ")
          ]),
          (list.value.length)
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(list.value, (o, index) => {
                return (_openBlock(), _createElementBlock("view", {
                  class: "body_video_list",
                  onClick: ($event: any) => (showInfo(o)),
                  key: index
                }, [
                  _createElementVNode("view", _hoisted_29, [
                    _createElementVNode("img", {
                      class: "body_VLL_img",
                      src: o.coverSheet,
                      style: {"width":"158px","height":"93px"}
                    }, null, 8, _hoisted_30),
                    _createElementVNode("span", _hoisted_31, _toDisplayString(o.videoTime), 1)
                  ]),
                  _createElementVNode("view", _hoisted_32, [
                    _createElementVNode("p", _hoisted_33, _toDisplayString(o.title), 1),
                    _createElementVNode("p", _hoisted_34, _toDisplayString(o.category1) + "-" + _toDisplayString(o.category2), 1)
                  ])
                ], 8, _hoisted_28))
              }), 128))
            : (_openBlock(), _createElementBlock("view", _hoisted_35, _cache[8] || (_cache[8] = [
                _createElementVNode("img", {
                  class: "no_data",
                  src: _imports_2
                }, null, -1)
              ]))),
          false
            ? (_openBlock(), _createElementBlock("view", _hoisted_36, _cache[9] || (_cache[9] = [
                _createElementVNode("span", { class: "body_video_right_t_title" }, " 相关推荐 ", -1)
              ])))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createElementVNode("view", _hoisted_37, [
      _createElementVNode("img", {
        onClick: openClick,
        class: "open",
        src: _imports_3
      }),
      _createElementVNode("view", _hoisted_38, [
        _createElementVNode("img", {
          class: "bodyUserImg",
          src: item.value.avatar ? item.value.avatar : require('@/assets/logo.png')
        }, null, 8, _hoisted_39),
        (
            ids.value.includes(Number(item.value.createUserId)) &&
            item.value.createUserId != memberId.value
          )
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              class: "open_list_img_spanN",
              style: {"color":"#fff","background":"#86909c"},
              onClick: handleDelete
            }, " 已关注 "))
          : (item.value.createUserId != memberId.value)
            ? (_openBlock(), _createElementBlock("button", {
                key: 1,
                class: "open_list_img_spanN",
                onClick: handleAdd
              }, " 关注 "))
            : _createCommentVNode("", true)
      ]),
      _createElementVNode("view", _hoisted_40, [
        _createElementVNode("view", { onClick: handleLike }, [
          _createElementVNode("img", {
            src: 
              handleLikeType.value
                ? require('@/assets/moduleSIWP_dz.png')
                : require('@/assets/moduleSIWP_dzLanSe.png')
            
          }, null, 8, _hoisted_41),
          _createTextVNode(" " + _toDisplayString(item.value.likes), 1)
        ]),
        _createElementVNode("view", { onClick: handleCollect }, [
          _createElementVNode("img", {
            src: 
              handleCollectType.value
                ? require('@/assets/moduleSIWP_sc.png')
                : require('@/assets/moduleSIWP_scLanSe.png')
            
          }, null, 8, _hoisted_42),
          _createTextVNode(" " + _toDisplayString(item.value.favorites), 1)
        ])
      ])
    ]),
    (ParticularsChecked.value)
      ? (_openBlock(), _createElementBlock("view", _hoisted_43, [
          _createVNode(moduleShare, {
            onParticularsOverNo: ParticularsOverNo,
            rwm: rwm.value,
            showImage: item.value.coverSheet
          }, null, 8, ["rwm", "showImage"])
        ]))
      : _createCommentVNode("", true),
    (module_informChecked.value)
      ? (_openBlock(), _createElementBlock("view", _hoisted_44, [
          _createElementVNode("view", _hoisted_45, [
            _createElementVNode("view", { class: "module_share_body_top" }, [
              _cache[10] || (_cache[10] = _createElementVNode("view", null, "选择举报理由", -1)),
              _createElementVNode("img", {
                onClick: module_informOverNo,
                src: _imports_4
              })
            ]),
            _createElementVNode("view", null, [
              _createElementVNode("view", null, [
                _createVNode(_component_el_radio_group, {
                  modelValue: type.value,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((type).value = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_row, { gutter: 20 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "1" }, {
                              default: _withCtx(() => _cache[11] || (_cache[11] = [
                                _createTextVNode("违法违禁")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "2" }, {
                              default: _withCtx(() => _cache[12] || (_cache[12] = [
                                _createTextVNode("政治敏感")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "3" }, {
                              default: _withCtx(() => _cache[13] || (_cache[13] = [
                                _createTextVNode("色情低俗")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "4" }, {
                              default: _withCtx(() => _cache[14] || (_cache[14] = [
                                _createTextVNode("人身攻击")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "5" }, {
                              default: _withCtx(() => _cache[15] || (_cache[15] = [
                                _createTextVNode("垃圾广告")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "6" }, {
                              default: _withCtx(() => _cache[16] || (_cache[16] = [
                                _createTextVNode("刷屏")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_el_col, { span: 8 }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_radio, { label: "7" }, {
                              default: _withCtx(() => _cache[17] || (_cache[17] = [
                                _createTextVNode("其他问题")
                              ])),
                              _: 1
                            })
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _withDirectives(_createElementVNode("textarea", {
                placeholder: "",
                maxLength: "100",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((reason).value = $event)),
                class: "module_share_textarea"
              }, null, 512), [
                [_vModelText, reason.value]
              ]),
              _createElementVNode("view", { class: "module_share_button" }, [
                _createElementVNode("button", {
                  class: "module_share_button_Y",
                  onClick: handleReport
                }, " 确定 "),
                _createElementVNode("button", {
                  class: "module_share_button_N",
                  onClick: module_informOverNo
                }, " 取消 ")
              ])
            ])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})